import { queryFetcher } from "./index";

type GetDataProps = {
  accountId: string;
  storeId: string;
  from: string;
  to: string;
};
const ENDPOINTS = {
  preFetchData: ({ to, from, storeId }: Omit<GetDataProps, "accountId">) =>
    `/api/marketing/meta-ads/${storeId}/creatives-stats?startDate=${from}&endDate=${to}`,
  data: ({ to, from, accountId, storeId }: GetDataProps) =>
    `/api/creative-overview/cards?storeId=${storeId}&accountId=${accountId}&startDate=${from}&endDate=${to}`,
  chartData: ({ to, from, accountId, storeId }: GetDataProps) =>
    `/api/creative-overview/charts?storeId=${storeId}&accountId=${accountId}&startDate=${from}&endDate=${to}`,
  videoData: ({ storeId, videoId }: GetVideoDataProps) =>
    `/api/creative-overview/video?storeId=${storeId}&videoId=${videoId}`,
};

export type GetVideoDataProps = {
  storeId: string | undefined;
  videoId: string | number;
};

function getData<T>(props: GetDataProps) {
  return queryFetcher<T>(ENDPOINTS.data(props));
}

export { ENDPOINTS };
