import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { useStoresContext } from "../StoresContext";
import { PinnedCardOrderItem, PinnedCards } from "../../lib/pinned/types";
import { useDateDropdown } from "../DateDropdownContext/DateDropdownContext";
import { LoadingCardId, TogglePinnedStatusParams } from "./types";
import { getEnumValueByTitle, getObjectKeyBySource } from "./helpers/helpers";
import { deleteCardFromState } from "./helpers/deleteCardFromState";
import { addCardToState } from "./helpers/addCardToState";
import { queryFetcher } from "../../src/APIResolvers";
import { useUpdatePinnedCard } from "./useUpdatePinnedCard";

type PinMetricsContextProps = {
  pinnedCardsData: PinnedCards;
  pinnedCardDisplayOrder: PinnedCardOrderItem[];
  loading: boolean;
  refetch: () => void;
  error: boolean;
  updatePinnedCardsDisplayOrder: (pinnedCardsDisplayOrder: PinnedCardOrderItem[]) => void;
  togglePinnedStatus: (newPinnedData: TogglePinnedStatusParams) => Promise<void>;
  isCardLoading: ({ cardKey, source }: LoadingCardId) => boolean;
  isCardPinned: ({ cardKey, source }: LoadingCardId) => boolean;
};

export const fetchPinnedCardsKey = "fetchPinnedCards";

const PinMetricsContext = React.createContext<PinMetricsContextProps | undefined>(undefined);

function PinMetricsProvider(props: any) {
  const { selectedStore } = useStoresContext();
  const {
    dateRange: { from, to },
    compareRange: { prevStartDate, prevEndDate },
  } = useDateDropdown();

  const [pinnedCardsData, setPinnedCardsData] = useState<PinnedCards | null>(null);
  const [pinnedCardDisplayOrder, setPinnedCardDisplayOrder] = useState<
    PinnedCardOrderItem[] | null
  >();
  const [loadingCardIds, setLoadingCardIds] = useState<LoadingCardId[]>([]);

  const { onAddPinnedCard, onDeletePinnedCard, onUpdatePinnedCardsDisplayOrder } =
    useUpdatePinnedCard();

  const router = useRouter();

  const setLoaders = useCallback((newLoadingCardId?: LoadingCardId) => {
    if (newLoadingCardId) {
      setLoadingCardIds((prev) => [...prev, newLoadingCardId]);
    }
  }, []);

  const removeLoader = useCallback((newLoadingCardId?: LoadingCardId) => {
    if (newLoadingCardId) {
      setLoadingCardIds((prev) =>
        prev.filter(
          (cardId) =>
            !(
              cardId.cardKey === newLoadingCardId.cardKey &&
              cardId.source === newLoadingCardId.source
            ),
        ),
      );
    }
  }, []);

  const {
    data: fetchPinnedCardData,
    error,
    isLoading,
    refetch,
  } = useQuery(
    [fetchPinnedCardsKey, selectedStore?.id, from, to, prevStartDate, prevEndDate],
    async () => {
      if (selectedStore?.id) {
        const baseCurrency = selectedStore?.adsCurrencySetting?.metaAdsCurrency?.label ?? "";
        const targetCurrency = selectedStore?.adsCurrencySetting?.storeCurrency ?? "";
        const response = await queryFetcher(
          `/api/dashboard/pinned-cards?storeId=${selectedStore.id}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}&baseCurrency=${baseCurrency}&targetCurrency=${targetCurrency}`,
        );

        if (response?.errorMessage) {
          throw new Error(response.errorMessage);
        }

        const cardsData = { ...response };
        delete cardsData.pinnedCardsDisplayOrder;
        return {
          pinnedCardsData: cardsData as PinnedCards,
          pinnedCardDisplayOrder: response.pinnedCardsDisplayOrder,
        };
      }
      return null;
    },
    {
      enabled: Boolean(selectedStore?.id),
      onSuccess: (data) => {
        setPinnedCardsData(data?.pinnedCardsData || null);
        setPinnedCardDisplayOrder(data?.pinnedCardDisplayOrder || null);
      },
    },
  );

  useEffect(() => {
    // When the "date" from useDateDropdown() changes, we update the pinnedCardsData & pinnedCardDisplayOrder states by taking data from the
    // useQuery()cache.
    if (fetchPinnedCardData) {
      setPinnedCardsData(fetchPinnedCardData.pinnedCardsData || null);
      setPinnedCardDisplayOrder(fetchPinnedCardData.pinnedCardDisplayOrder || null);
    }
  }, [fetchPinnedCardData]);

  const updatePinnedCardsDisplayOrder = useCallback(
    (pinnedCardsDisplayOrder: PinnedCardOrderItem[]) => {
      onUpdatePinnedCardsDisplayOrder({
        storeId: selectedStore?.id || "",
        pinnedCardsDisplayOrder,
      });
    },
    [onUpdatePinnedCardsDisplayOrder, selectedStore?.id],
  );

  useEffect(() => {
    if (router.pathname === "/dashboard") {
      refetch();
    }
  }, [router.pathname, refetch]);

  const isCardPinned = useCallback(
    ({ cardKey, source }: LoadingCardId) => {
      const sourceKey = getObjectKeyBySource(source) as keyof typeof pinnedCardsData;
      if (pinnedCardsData && sourceKey && pinnedCardsData?.[sourceKey]) {
        return Object.keys(pinnedCardsData[sourceKey]).findIndex((key) => cardKey === key) > -1;
      }
      return false;
    },
    [pinnedCardsData],
  );

  const togglePinnedStatus = useCallback(
    async ({ source, cardKey, cardData }: TogglePinnedStatusParams) => {
      const id = { source, cardKey };
      const service = source;

      if (selectedStore?.id) {
        setLoaders(id);
        try {
          const metricId = getEnumValueByTitle(id.cardKey, service) as any;

          if (isCardPinned(id)) {
            await onDeletePinnedCard({
              storeId: selectedStore.id,
              service,
              metricId,
            });
            setPinnedCardsData((prevState) => deleteCardFromState(prevState as PinnedCards, id));
          } else {
            await onAddPinnedCard({
              storeId: selectedStore.id,
              service,
              metricId,
            });
            setPinnedCardsData((prevState) =>
              addCardToState(prevState as PinnedCards, {
                source,
                cardKey,
                cardData,
              }),
            );
          }
        } catch {
          toast.error("Something went wrong, try again later");
        } finally {
          removeLoader(id);
        }
      }
    },
    [
      selectedStore?.id,
      setLoaders,
      removeLoader,
      isCardPinned,
      onDeletePinnedCard,
      onAddPinnedCard,
    ],
  );

  const isCardLoading = useCallback(
    ({ cardKey, source }: LoadingCardId) =>
      loadingCardIds.findIndex((cardId) => cardId.cardKey === cardKey && cardId.source === source) >
      -1,
    [loadingCardIds],
  );

  const value = useMemo(
    () => ({
      pinnedCardDisplayOrder,
      pinnedCardsData,
      loading: isLoading,
      error: !!error,
      isCardLoading,
      togglePinnedStatus,
      isCardPinned,
      refetch,
      updatePinnedCardsDisplayOrder,
    }),
    [
      isLoading,
      error,
      isCardLoading,
      togglePinnedStatus,
      isCardPinned,
      refetch,
      pinnedCardsData,
      pinnedCardDisplayOrder,
      updatePinnedCardsDisplayOrder,
    ],
  );

  return <PinMetricsContext.Provider value={value} {...props} />;
}

function usePinMetrics() {
  const context = React.useContext(PinMetricsContext);
  if (context === undefined) {
    throw new Error("usePinMetrics must be used within an PinMetricsProvider");
  }
  return context;
}

export { PinMetricsProvider, usePinMetrics, PinMetricsContext };
