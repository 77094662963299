import React, { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useRutterLink } from "react-rutter-link";
import { useIntercom } from "../src/hooks/useIntercom";
import { useAuthContext } from "./AmplifyContext";
import { useOnboardingStorageContext } from "./OnboardingStorageContext";
import { useStoresContext } from "./StoresContext";

const PUBLIC_KEY = process.env.NEXT_PUBLIC_RUTTER_PUBLIC_KEY || "RUTTER_PUBLIC_KEY";

type RutterConnectionsContextType = {
  isOnboardingShopifyConnected: boolean;
  isOnboardingWoocommerceConnected: boolean;
  isOnboardingMahentoConnected: boolean;
  isOnboardingBigCommerceConnected: boolean;
  isOnboardingSalesChannelsConnected: boolean;
  handleShopifyConnect: () => void;
  handleShopifyDisconnect: () => void;
  handleWoocommerceConnect: () => void;
  handleWoocommerceDisconnect: () => void;
  handleMagentoConnect: () => void;
  handleMagentoDisconnect: () => void;
  handleBigCommerceConnect: () => void;
  handleBigCommerceDisconnect: () => void;
  isLoading: boolean;
};

const RutterConnectionsContext = React.createContext<RutterConnectionsContextType>({
  handleShopifyConnect: () => {},
  handleShopifyDisconnect: () => {},
  handleWoocommerceConnect: () => {},
  handleWoocommerceDisconnect: () => {},
  handleMagentoConnect: () => {},
  handleMagentoDisconnect: () => {},
  handleBigCommerceConnect: () => {},
  handleBigCommerceDisconnect: () => {},
  isLoading: true,
  isOnboardingShopifyConnected: false,
  isOnboardingWoocommerceConnected: false,
  isOnboardingMahentoConnected: false,
  isOnboardingBigCommerceConnected: false,
  isOnboardingSalesChannelsConnected: false,
});

function useRutterConnectionsContext() {
  const context = React.useContext(RutterConnectionsContext);
  if (context === undefined) {
    throw new Error("usePageAccessContext must be used within an StoresProvider");
  }
  return context;
}

function RutterConnectionsProvider(props: any) {
  const { authedUser } = useAuthContext();
  const { selectedStore } = useStoresContext();
  const { trackIntercomEvent } = useIntercom();
  const handleEvent = (event: () => void, eventName: string, label: string) => {
    event();
    trackIntercomEvent("trackEvent", `${eventName} ${label}`);
  };

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [isOnboardingShopifyConnected, setIsOnboardingShopifyConnected] = React.useState(false);
  const [isOnboardingWoocommerceConnected, setIsOnboardingWoocommerceConnected] =
    React.useState(false);
  const [isOnboardingMahentoConnected, setIsOnboardingMahentoConnected] = React.useState(false);
  const [isOnboardingBigCommerceConnected, setIsOnboardingBigCommerceConnected] =
    React.useState(false);

  const { onboardingData } = useOnboardingStorageContext();
  const onboardingStoreId = onboardingData?.storeId;
  const storeName = onboardingData?.storeName || selectedStore?.storeName;

  const queryClient = useQueryClient();

  const isOnboarding = !!onboardingStoreId;

  useEffect(() => {
    if (!isOnboarding) {
      setIsOnboardingShopifyConnected(!!selectedStore?.isShopifyStoreConnected);
      setIsOnboardingMahentoConnected(!!selectedStore?.isMagentoStoreConnected);
      setIsOnboardingWoocommerceConnected(!!selectedStore?.isWoocommerceStoreConnected);
      setIsOnboardingBigCommerceConnected(!!selectedStore?.isBigCommerceStoreConnected);
    } else {
      setIsOnboardingShopifyConnected(false);
      setIsOnboardingMahentoConnected(false);
      setIsOnboardingWoocommerceConnected(false);
      setIsOnboardingBigCommerceConnected(false);
    }
  }, [
    isOnboarding,
    selectedStore?.isMagentoStoreConnected,
    selectedStore?.isShopifyStoreConnected,
    selectedStore?.isWoocommerceStoreConnected,
    selectedStore?.isBigCommerceStoreConnected,
  ]);

  const rutterConfig = {
    publicKey: PUBLIC_KEY,
    onSuccess: (publicToken: string) => {
      setIsLoading(true);
      fetch("/api/rutter/rutter-exchange", {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          publicToken,
          storeName: storeName || "",
          storeId: onboardingStoreId || localStorage.getItem("store-id"),
          username: authedUser?.username,
        }),
      })
        .then((res) => res.json())
        .then(({ platform }) => {
          if (platform === "SHOPIFY") {
            handleEvent(() => setIsOnboardingShopifyConnected(true), "Connect", "Shopify");
          }
          if (platform === "WOO_COMMERCE") {
            handleEvent(() => setIsOnboardingWoocommerceConnected(true), "Connect", "WooCommerce");
          }
          if (platform === "MAGENTO") {
            handleEvent(() => setIsOnboardingMahentoConnected(true), "Connect", "Magento");
          }
          if (platform === "BIG_COMMERCE") {
            handleEvent(() => setIsOnboardingBigCommerceConnected(true), "Connect", "BigCommerce");
          }
          setTimeout(() => {
            queryClient.invalidateQueries("list-stores");
          }, 7000);
        })
        .catch((e) => {
          console.error(e);
          console.log("catch", e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    onError: (error: any) => {
      console.error(error);
      console.log("onError", error);
    },
  };

  const disconnectSalesChannels = useCallback(
    (platform: string) => {
      const storeId = selectedStore?.id || localStorage.getItem("store-id");
      setIsLoading(true);
      fetch(`/api/rutter/disconnect?storeId=${storeId}`, {
        method: "GET",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
      })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          if (platform === "SHOPIFY") {
            handleEvent(() => setIsOnboardingShopifyConnected(false), "Disconnect", "Shopify");
          }
          if (platform === "WOO_COMMERCE") {
            handleEvent(
              () => setIsOnboardingWoocommerceConnected(false),
              "Disconnect",
              "WooCommerce",
            );
          }
          if (platform === "MAGENTO") {
            handleEvent(() => setIsOnboardingMahentoConnected(false), "Disconnect", "Magento");
          }
          if (platform === "BIG_COMMERCE") {
            handleEvent(
              () => setIsOnboardingBigCommerceConnected(false),
              "Disconnect",
              "BigCommerce",
            );
          }
          setIsLoading(false);
        });
    },
    [selectedStore?.id],
  );

  const { open } = useRutterLink(rutterConfig);

  const handleShopifyConnect = React.useCallback(() => open({ platform: "SHOPIFY" }), [open]);
  const handleShopifyDisconnect = React.useCallback(
    () => disconnectSalesChannels("SHOPIFY"),
    [disconnectSalesChannels],
  );
  const handleWoocommerceConnect = React.useCallback(
    () => open({ platform: "WOOCOMMERCE" }),
    [open],
  );
  const handleWoocommerceDisconnect = React.useCallback(
    () => disconnectSalesChannels("WOO_COMMERCE"),
    [disconnectSalesChannels],
  );
  const handleMagentoConnect = React.useCallback(() => open({ platform: "MAGENTO" }), [open]);
  const handleMagentoDisconnect = React.useCallback(
    () => disconnectSalesChannels("MAGENTO"),
    [disconnectSalesChannels],
  );
  const handleBigCommerceConnect = React.useCallback(
    () => open({ platform: "BIG_COMMERCE" }),
    [open],
  );
  const handleBigCommerceDisconnect = React.useCallback(
    () => disconnectSalesChannels("BIG_COMMERCE"),
    [disconnectSalesChannels],
  );

  const value: RutterConnectionsContextType = React.useMemo(
    () => ({
      isOnboardingShopifyConnected,
      isOnboardingWoocommerceConnected,
      isOnboardingMahentoConnected,
      isOnboardingBigCommerceConnected,
      isOnboardingSalesChannelsConnected:
        isOnboardingShopifyConnected ||
        isOnboardingWoocommerceConnected ||
        isOnboardingMahentoConnected ||
        isOnboardingBigCommerceConnected,
      isLoading,
      handleShopifyConnect,
      handleWoocommerceConnect,
      handleShopifyDisconnect,
      handleWoocommerceDisconnect,
      handleMagentoConnect,
      handleMagentoDisconnect,
      handleBigCommerceConnect,
      handleBigCommerceDisconnect,
    }),
    [
      handleShopifyConnect,
      handleShopifyDisconnect,
      handleWoocommerceConnect,
      handleWoocommerceDisconnect,
      handleMagentoConnect,
      handleMagentoDisconnect,
      isLoading,
      isOnboardingShopifyConnected,
      isOnboardingWoocommerceConnected,
      isOnboardingMahentoConnected,
      isOnboardingBigCommerceConnected,
      handleBigCommerceConnect,
      handleBigCommerceDisconnect,
    ],
  );

  return <RutterConnectionsContext.Provider value={value} {...props} />;
}

export { RutterConnectionsProvider, useRutterConnectionsContext, RutterConnectionsContext };
