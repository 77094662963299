import { useMutation, useQueryClient } from "react-query";
import { PinnedCardOrderItem, PinnedService } from "../../lib/pinned/types";
import api, { queryFetcher } from "../../src/APIResolvers";
import { fetchPinnedCardsKey } from "./PinMetricsContext";

type UpdatePinnedCardProps = {
  storeId: string;
  service: PinnedService;
  metricId: number;
};

type UpdatePinnedCardDisplayOrderProps = {
  storeId: string;
  pinnedCardsDisplayOrder: PinnedCardOrderItem[];
};

export function useUpdatePinnedCard() {
  const queryClient = useQueryClient();

  const { mutateAsync: onAddPinnedCard } = useMutation((values: UpdatePinnedCardProps) =>
    queryFetcher(api.updatePinnedCard.ENDPOINTS.updatePinnedCard(), {
      method: "POST",
      body: JSON.stringify(values),
    }),
  );

  const { mutateAsync: onDeletePinnedCard } = useMutation((values: UpdatePinnedCardProps) =>
    queryFetcher(api.updatePinnedCard.ENDPOINTS.updatePinnedCard(), {
      method: "DELETE",
      body: JSON.stringify(values),
    }),
  );

  const { mutateAsync: onUpdatePinnedCardsDisplayOrder } = useMutation(
    (values: UpdatePinnedCardDisplayOrderProps) =>
      queryFetcher(api.updatePinnedCard.ENDPOINTS.updatePinnedCardsDisplayOrder(), {
        method: "POST",
        body: JSON.stringify(values),
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(fetchPinnedCardsKey),
    },
  );

  return { onAddPinnedCard, onDeletePinnedCard, onUpdatePinnedCardsDisplayOrder };
}
